<template>
  <svg
    class="w-0 h-0 opacity-0 pointer-events-none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <symbol
      id="twitter-x-icon"
      fill="none"
      viewBox="0 0 17 16"
    >
      <g clip-path="url(#clip0_10525_75479)">
        <path
          d="M9.74933 6.77143L15.6181 0H14.2274L9.13154 5.87954L5.0615 0H0.367188L6.52189 8.8909L0.367188 15.9918H1.75798L7.13932 9.78279L11.4376 15.9918H16.1319L9.74899 6.77143H9.74933ZM7.84445 8.96923L7.22085 8.0839L2.2591 1.03921H4.39527L8.39946 6.7245L9.02306 7.60983L14.228 14.9998H12.0919L7.84445 8.96957V8.96923Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10525_75479">
          <rect
            fill="currentColor"
            height="16"
            transform="translate(0.367188)"
            width="15.7647"
          />
        </clipPath>
      </defs>
    </symbol>
    <symbol
      id="share-social-icon"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        d="M9.03097 0.900391C4.55815 0.900391 0.93185 4.52669 0.93185 9.00027C0.93185 10.642 1.42162 12.1681 2.26079 13.4448L0.869141 17.1009L4.29304 15.5682C5.62573 16.5305 7.26147 17.1009 9.03095 17.1009C13.5045 17.1009 17.1316 13.4739 17.1316 9.00027C17.1316 4.52669 13.5045 0.900391 9.03097 0.900391ZM13.0768 8.19849C12.9439 9.07126 12.5378 9.59612 12.0711 10.1008C11.0446 11.17 9.81045 11.9412 9.02491 12.6631C8.23277 12.0098 7.83931 11.8023 7.21591 11.2602C6.62834 10.7496 5.95938 10.2165 5.49947 9.57443C5.05375 8.95251 4.69164 7.85948 4.97311 6.86803C5.20381 6.05499 5.84067 5.40545 6.90601 5.33676C8.20733 5.32332 8.70905 6.19759 9.0092 6.85161C9.31306 6.21028 9.72445 5.42561 10.9734 5.33676C11.6954 5.33004 12.2046 5.62122 12.5353 5.95495C13.0565 6.4813 13.2185 7.27493 13.0766 8.19843L13.0768 8.19849Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="bar-chart-icon"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        d="M11.0703 2H14.9986V14.6228H11.0703V2Z"
        fill="currentColor"
      />
      <path
        d="M6.03711 6.26465H9.96539V14.6221H6.03711V6.26465Z"
        fill="currentColor"
      />
      <path
        d="M1 8.78027H4.92828V14.6235H1V8.78027Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="clipboard-icon"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        clip-rule="evenodd"
        d="M8.52892 4.53412H15.4665C16.9986 4.53412 18.2517 5.78724 18.2517 7.32012V16.5701C18.2517 18.1022 16.9986 19.3553 15.4665 19.3553H8.52892C6.99688 19.3553 5.74292 18.1022 5.74292 16.5701V7.32012C5.74292 5.78808 6.99604 4.53412 8.52892 4.53412ZM4.53432 0.645915H11.4719C13.004 0.645915 14.2579 1.89903 14.2579 3.43111V3.78735H8.52892C6.58596 3.78735 4.99692 5.37642 4.99692 7.31936V15.4648H4.5352C3.00316 15.4648 1.75 14.2116 1.75 12.6788V3.42876C1.75 1.89671 3.00312 0.643555 4.5352 0.643555L4.53432 0.645915Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol
      id="link-icon"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        d="M11.3089 9.22064L13.2097 7.31924C14.5966 5.93291 14.5966 3.67649 13.2097 2.29019C11.8233 0.903271 9.56692 0.903271 8.18002 2.29019L5.43667 5.03354C4.04976 6.41987 4.04976 8.67629 5.43667 10.0626C5.64117 10.2677 5.86558 10.4411 6.10288 10.5852L7.57125 9.1163C7.29175 9.0495 7.02633 8.90829 6.80836 8.69033C6.1779 8.05986 6.1779 7.03448 6.80836 6.40403L9.55171 3.66068C10.1822 3.03021 11.2076 3.03021 11.838 3.66068C12.4685 4.29114 12.4685 5.31653 11.838 5.94698L11.0323 6.75323C11.3646 7.53545 11.4559 8.39564 11.3089 9.22064Z"
        fill="currentColor"
      />
      <path
        d="M3.69099 6.77965L1.79019 8.68045C0.403271 10.0668 0.403271 12.3232 1.79019 13.7101C3.17652 15.097 5.43294 15.097 6.81984 13.7101L9.56319 10.9667C10.9501 9.58042 10.9495 7.324 9.56319 5.9377C9.35869 5.73262 9.13428 5.55919 8.89756 5.41504L7.4292 6.88399C7.70869 6.95079 7.97412 7.09199 8.19208 7.30996C8.82255 7.94042 8.82255 8.96581 8.19208 9.59626L5.44873 12.3396C4.81827 12.9701 3.79288 12.9701 3.16243 12.3396C2.53197 11.7091 2.53197 10.6838 3.16243 10.0533L3.96868 9.24764C3.63528 8.46482 3.54393 7.60465 3.69099 6.77965Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="wallet-icon"
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        d="M9.29935 9.62457V7.99835C9.29935 7.08187 10.0536 6.32763 10.9701 6.32763H13.1582V5.1299C13.1582 4.46463 12.6113 3.91774 11.9461 3.91774V3.22302C11.9461 2.35101 11.2363 1.6413 10.3644 1.6413H8.56028L7.37757 0L5.08608 1.6413H1.56738C0.724821 1.6413 0.030159 2.30657 0 3.13476V3.23813V12.6108C0 13.3944 0.635823 14.0447 1.43391 14.0447H11.7238C12.5074 14.0447 13.1577 13.4089 13.1577 12.6108V11.2953L10.9695 11.2947C10.053 11.2947 9.29935 10.5555 9.29935 9.62457ZM10.3637 2.52833C10.7483 2.52833 11.0584 2.83903 11.0584 3.22305V3.91777H10.1864L9.19599 2.52833H10.3637ZM9.09203 3.91777H3.42967L7.17013 1.22726L9.09203 3.91777ZM1.56735 2.52833H3.85885L1.92187 3.91777H1.56729C1.18268 3.91777 0.872571 3.60708 0.872571 3.22305C0.872571 2.83844 1.18275 2.52833 1.56735 2.52833Z"
        fill="currentColor"
      />
      <path
        d="M13.6451 6.96289H10.969C10.3926 6.96289 9.93408 7.42143 9.93408 7.99776V9.62398C9.93408 10.2003 10.3926 10.6588 10.969 10.6588H13.6451C13.8374 10.6588 13.9997 10.496 13.9997 10.3043V7.3175C13.9997 7.11076 13.8374 6.96289 13.6451 6.96289ZM11.5309 9.57948C11.1168 9.57948 10.7622 9.23933 10.7622 8.82527C10.7622 8.4112 11.1024 8.05663 11.5309 8.05663C11.9449 8.05663 12.2851 8.39677 12.2851 8.82527C12.2995 9.23933 11.96 9.57948 11.5309 9.57948Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="close-x-icon"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.00048 7.05781L11.3005 3.75781L12.2431 4.70048L8.94315 8.00048L12.2431 11.3005L11.3005 12.2431L8.00048 8.94315L4.70048 12.2431L3.75781 11.3005L7.05781 8.00048L3.75781 4.70048L4.70048 3.75781L8.00048 7.05781Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="input-search-icon"
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        d="M14.2742 12.4628L17.6649 15.6743L16.5447 16.7355L13.1548 13.5233C11.8935 14.4812 10.3246 15.0022 8.70801 15C4.77501 15 1.58301 11.976 1.58301 8.25C1.58301 4.524 4.77501 1.5 8.70801 1.5C12.641 1.5 15.833 4.524 15.833 8.25C15.8353 9.7815 15.2853 11.2678 14.2742 12.4628ZM12.6861 11.9063C13.6908 10.9274 14.2519 9.61532 14.2497 8.25C14.2497 5.349 11.7694 3 8.70801 3C5.64584 3 3.16634 5.349 3.16634 8.25C3.16634 11.1503 5.64584 13.5 8.70801 13.5C10.1492 13.5022 11.5342 12.9706 12.5674 12.0188L12.6861 11.9063Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="circle-warning"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        d="M7 0C3.12698 0 0 3.12698 0 7C0 10.873 3.12698 14 7 14C10.873 14 14 10.873 14 7C14 3.12698 10.873 0 7 0ZM7.77778 10.2508C7.77778 10.6707 7.41988 11.0128 7 11.0128C6.58012 11.0128 6.22222 10.6707 6.22222 10.2508V6.26889C6.22222 5.84901 6.58012 5.50692 7 5.50692C7.41988 5.50692 7.77778 5.84901 7.77778 6.26889V10.2508ZM7.0158 4.51111C6.59593 4.51111 6.25383 4.16901 6.25383 3.74914C6.25383 3.32926 6.59593 2.98716 7.0158 2.98716C7.43568 2.98716 7.77778 3.32926 7.77778 3.74914C7.77778 4.16901 7.43568 4.51111 7.0158 4.51111Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="small-circle-check"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        d="M7 14C10.858 14 14 10.858 14 7C14 3.14202 10.858 0 7 0C3.14202 0 0 3.14202 0 7C0 10.858 3.14202 14 7 14ZM3.4186 6.72342C3.67933 6.46269 4.08631 6.46269 4.34638 6.72342L5.87635 8.25339L9.32735 4.80239C9.58808 4.54167 9.99506 4.54167 10.2551 4.80239C10.5159 5.06312 10.5159 5.47009 10.2551 5.73017L6.34815 9.63714C6.21779 9.7675 6.055 9.83236 5.89221 9.83236C5.72942 9.83236 5.56663 9.7675 5.43626 9.63714L3.41847 7.65126C3.17429 7.39053 3.17442 6.98349 3.4186 6.72342Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="small-circle-warning"
      fill="none"
      viewBox="0 0 7 7"
    >
      <path
        d="M3.5 0C1.56955 0 0 1.56955 0 3.5C0 5.43045 1.56955 7 3.5 7C5.43045 7 7 5.43045 7 3.5C7 1.56955 5.43045 0 3.5 0ZM3.5 1.23797C3.80222 1.23797 4.04515 1.48838 4.03047 1.7906L3.92743 3.83909C3.91275 4.06763 3.72854 4.24436 3.50749 4.24436C3.27895 4.24436 3.09474 4.06763 3.08755 3.83909L2.98451 1.7906C2.95486 1.49586 3.19778 1.23797 3.5 1.23797ZM3.5 5.76203C3.19059 5.76203 2.93988 5.51162 2.93988 5.20191C2.93988 4.8925 3.19029 4.6418 3.5 4.6418C3.80941 4.6418 4.06012 4.8922 4.06012 5.20191C4.06012 5.51162 3.80942 5.76203 3.5 5.76203Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="circle-check-icon"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        d="M10.2603 19.5C15.4961 19.5 19.7603 15.2358 19.7603 10C19.7603 4.76417 15.4961 0.5 10.2603 0.5C5.02443 0.5 0.760254 4.76417 0.760254 10C0.760254 15.2358 5.02443 19.5 10.2603 19.5ZM5.39979 9.62464C5.75363 9.2708 6.30596 9.2708 6.65891 9.62464L8.7353 11.701L13.4188 7.01753C13.7726 6.66369 14.325 6.66369 14.6779 7.01753C15.0318 7.37137 15.0318 7.9237 14.6779 8.27666L9.37561 13.579C9.19869 13.7559 8.97775 13.8439 8.75682 13.8439C8.53589 13.8439 8.31496 13.7559 8.13804 13.579L5.39961 10.8839C5.06822 10.53 5.06839 9.9776 5.39979 9.62464Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="user-circle-icon"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        d="M8 0.5C3.57333 0.5 0 4.07333 0 8.5C0 12.9267 3.57333 16.5 8 16.5C12.4267 16.5 16 12.9267 16 8.5C16 4.07333 12.4267 0.5 8 0.5ZM7.99997 2.53222C9.64792 2.53222 10.9839 3.86815 10.9839 5.5161C10.9839 7.16406 9.64792 8.5 7.99997 8.5C6.35201 8.5 5.01607 7.16406 5.01607 5.5161C5.01607 3.86815 6.35201 2.53222 7.99997 2.53222ZM7.99998 14.7222C6.31138 14.7222 4.77878 14.0451 3.65678 12.9492C3.43566 12.7332 3.35249 12.412 3.42638 12.1119L3.5979 11.4152C3.88546 10.2074 4.95349 9.36376 6.19507 9.36376H9.80493C11.0466 9.36376 12.1145 10.2073 12.402 11.4152L12.5676 12.1333C12.6363 12.431 12.5513 12.747 12.3322 12.96C11.2112 14.0496 9.68306 14.7222 7.99998 14.7222Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="external-link-icon"
      fill="none"
      viewBox="0 0 7 7"
    >
      <path
        d="M6.94416 4.56808V0.881698C6.97261 0.654995 6.89687 0.418688 6.72652 0.248472C6.55618 0.0782553 6.3197 0.0025615 6.09283 0.0309939H2.40374C2.01576 0.0309939 1.66585 0.304958 1.60932 0.692653C1.53357 1.17487 1.91194 1.59062 2.37567 1.59062H4.27716C4.28676 1.59062 4.28676 1.60022 4.27716 1.60022L1.19298 4.68147C0.918818 4.95543 0.861911 5.39999 1.09839 5.71157C1.38217 6.09927 1.94011 6.12732 2.27114 5.7965L5.38341 2.69644H5.39302V4.59653C5.39302 5.05954 5.80907 5.43763 6.29164 5.36232C6.67001 5.30546 6.94416 4.9554 6.94416 4.56808Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="base-close-icon"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        d="M6.10781 4.85403L10.2773 0.68457L11.4683 1.8756L7.29884 6.04507L11.4683 10.2145L10.2773 11.4056L6.10781 7.2361L1.93835 11.4056L0.747314 10.2145L4.91678 6.04507L0.747314 1.8756L1.93835 0.68457L6.10781 4.85403Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="np-link-icon"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        d="M14.95 11.3193L17.3577 8.91084C19.1145 7.15483 19.1145 4.29669 17.3577 2.54071C15.6017 0.783955 12.7436 0.783955 10.9868 2.54071L7.51191 6.01562C5.75515 7.77164 5.75515 10.6298 7.51191 12.3858C7.77093 12.6455 8.05519 12.8652 8.35577 13.0478L10.2157 11.1871C9.86167 11.1025 9.52547 10.9236 9.24938 10.6476C8.45079 9.84897 8.45079 8.55015 9.24938 7.75158L12.7243 4.27667C13.5229 3.47808 14.8217 3.47808 15.6203 4.27667C16.4189 5.07526 16.4189 6.37408 15.6203 7.17265L14.5998 8.1939C15.0206 9.18471 15.1363 10.2743 14.95 11.3193Z"
        fill="currentColor"
      />
      <path
        d="M5.29947 8.22729L2.89179 10.635C1.13503 12.391 1.13503 15.2491 2.89179 17.0059C4.64781 18.7626 7.50594 18.7626 9.26268 17.0059L12.7376 13.5309C14.4943 11.7749 14.4936 8.9168 12.7376 7.16082C12.4786 6.90105 12.1943 6.68137 11.8945 6.49878L10.0345 8.35945C10.3886 8.44406 10.7248 8.62292 11.0009 8.89901C11.7994 9.6976 11.7994 10.9964 11.0009 11.795L7.52595 15.2699C6.72736 16.0685 5.42854 16.0685 4.62997 15.2699C3.83138 14.4713 3.83138 13.1725 4.62997 12.3739L5.65122 11.3534C5.2289 10.3618 5.11319 9.27229 5.29947 8.22729Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="telegram-icon"
      fill="none"
      viewBox="0 0 19 16"
    >
      <g clip-path="url(#clip0_1_15828)">
        <path
          d="M0.911343 8.18668C0.290409 8.00237 0.166393 7.26351 0.763465 7.0119L16.4134 0.323088C17.6293 -0.200438 18.491 0.447999 18.3623 1.31309C17.7426 5.47922 17.123 9.64535 16.5034 13.8115C16.3344 14.9473 15.0388 15.5116 14.0986 14.8584C12.1293 13.49 9.84281 11.9013 8.57757 11.0222C8.07483 10.6728 8.01351 9.95159 8.45016 9.52186C9.63736 8.35348 11.8749 6.15139 13.7988 4.25807C14.0101 4.05011 13.7373 3.71547 13.4929 3.88299C11.0891 5.53123 8.23702 7.48681 6.61426 8.59948C5.7895 9.165 4.75597 9.32779 3.79833 9.04356L0.911343 8.18668Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_15828">
          <rect
            fill="white"
            height="15"
            transform="translate(0.375 0.135254)"
            width="18"
          />
        </clipPath>
      </defs>
    </symbol>
    <symbol
      id="twitter-icon"
      fill="none"
      viewBox="0 0 20 17"
    >
      <g clip-path="url(#clip0_1_15832)">
        <path
          d="M17.2021 3.27812C18.097 2.69656 18.5199 2.33843 18.8601 1.12633C18.8601 1.12633 17.2327 2.05379 16.3685 2.05379C16.3557 2.05379 16.3404 2.05596 16.3265 2.05697C15.6122 1.30338 14.6019 0.83252 13.4808 0.83252C11.3164 0.83252 9.56175 2.58489 9.56175 4.74655C9.56175 5.02421 9.59115 5.29497 9.6462 5.55631C9.63145 5.56401 9.61611 5.57224 9.60148 5.57987C5.31694 5.76353 1.63922 1.53956 1.63922 1.53956C0.315242 4.27597 1.79552 5.9931 2.76092 6.82871C2.24145 6.75983 1.68978 6.66802 1.08756 6.36957C1.08756 7.23272 1.25765 9.28962 4.12168 10.2262C3.59239 10.3609 3.05443 10.2899 2.44847 10.2829C2.44847 10.2829 2.87126 12.7667 6.01571 12.9994C4.5722 14.1104 3.01836 14.7624 0.375 14.6247C2.43453 15.8123 6.34056 17.6273 11.1829 15.3501L11.9928 14.8845C15.1775 13.0539 17.2087 9.72886 17.3817 6.06318L17.4473 4.67386C18.2073 4.35401 19.0992 3.23373 19.375 2.77002C18.5957 3.03685 18.0602 3.18018 17.2021 3.27812Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_15832">
          <rect
            fill="white"
            height="15.6071"
            transform="translate(0.375 0.83252)"
            width="19"
          />
        </clipPath>
      </defs>
    </symbol>
    <symbol
      id="instagram-icon"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clip-path="url(#clip0_1_15835)">
        <path
          d="M8.62496 5.94387C10.1879 5.94387 11.455 7.21092 11.455 8.77389C11.455 10.3369 10.1879 11.6039 8.62496 11.6039C7.06198 11.6039 5.79495 10.3369 5.79495 8.77389C5.79495 7.21092 7.06199 5.94387 8.62496 5.94387ZM8.62496 4.37939C6.20182 4.37939 4.23047 6.35075 4.23047 8.77389C4.23047 11.197 6.20182 13.1684 8.62496 13.1684C11.0481 13.1684 13.0195 11.197 13.0195 8.77389C13.0195 6.35076 11.0481 4.37939 8.62496 4.37939Z"
          fill="currentColor"
        />
        <path
          d="M12.3718 1.8384C14.1329 1.8384 15.5605 3.26606 15.5605 5.02715V12.5207C15.5605 14.2818 14.1329 15.7094 12.3718 15.7094H4.87823C3.11713 15.7094 1.68948 14.2818 1.68948 12.5207V5.02715C1.68948 3.26606 3.11713 1.8384 4.87823 1.8384H12.3718ZM12.3718 0.273926H4.87823C2.25728 0.273926 0.125 2.40621 0.125 5.02715V12.5207C0.125 15.1416 2.25728 17.2739 4.87823 17.2739H12.3718C14.9927 17.2739 17.125 15.1416 17.125 12.5207V5.02715C17.125 2.40621 14.9927 0.273926 12.3718 0.273926Z"
          fill="currentColor"
        />
        <path
          d="M13.2483 5.18645C13.8206 5.18645 14.2846 4.72247 14.2846 4.15011C14.2846 3.57776 13.8206 3.11377 13.2483 3.11377C12.6759 3.11377 12.2119 3.57776 12.2119 4.15011C12.2119 4.72247 12.6759 5.18645 13.2483 5.18645Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_15835">
          <rect
            fill="white"
            height="17"
            transform="translate(0.125 0.273926)"
            width="17"
          />
        </clipPath>
      </defs>
    </symbol>
    <symbol
      id="facebook-icon"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        d="M8.01415 18.4859H11.3131V11.8798H14.2854L14.612 8.59731H11.3131V6.9396C11.3131 6.72086 11.4 6.51109 11.5547 6.35642C11.7093 6.20175 11.9191 6.11486 12.1378 6.11486H14.612V2.81592H12.1378C11.0442 2.81592 9.99529 3.25038 9.22195 4.02372C8.44861 4.79705 8.01415 5.84593 8.01415 6.9396V8.59731H6.36468L6.03809 11.8798H8.01415V18.4859Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="google-icon"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        d="M2.17152 6.20306C2.76306 4.93091 3.67053 3.86149 4.7925 3.11433C5.91446 2.36717 7.20672 1.97172 8.52483 1.97217C10.4409 1.97217 12.0506 2.73311 13.2813 3.97244L11.2429 6.17465C10.5056 5.4137 9.56854 5.02594 8.52483 5.02594C6.67273 5.02594 5.10502 6.37737 4.5469 8.19181C4.40471 8.65253 4.32365 9.14395 4.32365 9.65074C4.32365 10.1575 4.40471 10.649 4.5469 11.1097C5.10573 12.9249 6.67273 14.2755 8.52483 14.2755C9.48109 14.2755 10.2952 14.003 10.9322 13.5422C11.3015 13.2796 11.6177 12.9389 11.8616 12.5406C12.1055 12.1423 12.2721 11.6947 12.3513 11.2248H8.52483V8.25478H15.2208C15.3047 8.75695 15.3502 9.28063 15.3502 9.82504C15.3502 12.1639 14.5753 14.1327 13.2301 15.4688C12.0541 16.6421 10.4445 17.3293 8.52483 17.3293C7.59105 17.3297 6.66636 17.1314 5.80359 16.7456C4.94082 16.3599 4.15689 15.7943 3.49661 15.0812C2.83634 14.3681 2.31265 13.5215 1.95548 12.5897C1.59831 11.6579 1.41467 10.6592 1.41504 9.65074C1.41504 8.41142 1.68948 7.23967 2.17152 6.20306Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      id="facebook-button-icon"
      fill="none"
      viewBox="0 0 29 28"
    >
      <g filter="url(#filter_facebook_button_icon)">
        <path
          d="M-0.0078125 5.46032C-0.0078125 2.44467 2.43685 0 5.4525 0H23.1985C26.2142 0 28.6589 2.44467 28.6589 5.46032V21.8413C28.6589 24.8569 26.2142 27.3016 23.1985 27.3016H5.4525C2.43685 27.3016 -0.0078125 24.8569 -0.0078125 21.8413V5.46032Z"
          fill="#112811"
        />
        <path
          d="M12.0142 21.4859H15.3131V14.8798H18.2854L18.612 11.5973H15.3131V9.9396C15.3131 9.72086 15.4 9.51109 15.5547 9.35642C15.7093 9.20175 15.9191 9.11486 16.1378 9.11486H18.612V5.81592H16.1378C15.0442 5.81592 13.9953 6.25038 13.2219 7.02372C12.4486 7.79705 12.0142 8.84593 12.0142 9.9396V11.5973H10.3647L10.0381 14.8798H12.0142V21.4859Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter_facebook_button_icon"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40.9526"
          width="42.3173"
          x="-6.83321"
          y="-6.8254"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="3.4127"
          />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_9902_194954"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_9902_194954"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </symbol>
    <symbol
      id="google-button-icon"
      fill="none"
      viewBox="0 0 31 28"
    >
      <g filter="url(#filter_google_button_icon)">
        <path
          d="M0.849121 5.46032C0.849121 2.44467 3.29379 0 6.30944 0H24.738C27.7537 0 30.1983 2.44467 30.1983 5.46032V21.8413C30.1983 24.8569 27.7537 27.3016 24.738 27.3016H6.30944C3.29379 27.3016 0.849121 24.8569 0.849121 21.8413V5.46032Z"
          fill="#112811"
        />
        <path
          d="M9.17152 10.2031C9.76306 8.93091 10.6705 7.86149 11.7925 7.11433C12.9145 6.36717 14.2067 5.97172 15.5248 5.97217C17.4409 5.97217 19.0506 6.73311 20.2813 7.97244L18.2429 10.1747C17.5056 9.4137 16.5685 9.02594 15.5248 9.02594C13.6727 9.02594 12.105 10.3774 11.5469 12.1918C11.4047 12.6525 11.3237 13.144 11.3237 13.6507C11.3237 14.1575 11.4047 14.649 11.5469 15.1097C12.1057 16.9249 13.6727 18.2755 15.5248 18.2755C16.4811 18.2755 17.2952 18.003 17.9322 17.5422C18.3015 17.2796 18.6177 16.9389 18.8616 16.5406C19.1055 16.1423 19.2721 15.6947 19.3513 15.2248H15.5248V12.2548H22.2208C22.3047 12.757 22.3502 13.2806 22.3502 13.825C22.3502 16.1639 21.5753 18.1327 20.2301 19.4688C19.0541 20.6421 17.4445 21.3293 15.5248 21.3293C14.5911 21.3297 13.6664 21.1314 12.8036 20.7456C11.9408 20.3599 11.1569 19.7943 10.4966 19.0812C9.83634 18.3681 9.31265 17.5215 8.95548 16.5897C8.59831 15.6579 8.41467 14.6592 8.41504 13.6507C8.41504 12.4114 8.68948 11.2397 9.17152 10.2031Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter_google_button_icon"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40.9526"
          width="42.9999"
          x="-5.97628"
          y="-6.8254"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="3.4127"
          />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_9902_194955"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_9902_194955"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </symbol>
    <symbol
      id="google-fill-icon"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        clip-rule="evenodd"
        d="M1.875 9.38537C1.875 4.6966 5.68056 1.13525 10.375 1.13525C15.0694 1.13525 18.875 4.62103 18.875 9.38537C18.875 13.9418 15.7017 17.6355 10.375 17.6355C9.61901 17.6355 8.88672 17.5475 8.18926 17.381C7.8703 17.3049 7.53556 17.3326 7.23797 17.4703L5.78056 18.1448C5.32746 18.3545 4.8097 18.0236 4.8097 17.5243V16.1638C4.8097 15.8984 4.69494 15.6473 4.49769 15.4697C2.88223 14.0156 1.875 11.9079 1.875 9.38537ZM5.26433 11.8286L7.78892 7.85108C8.17572 7.24167 8.99641 7.08485 9.58064 7.50871L11.6651 9.02093C11.8521 9.15663 12.1061 9.15318 12.2894 9.01247L14.9032 7.00578C15.2779 6.71818 15.7708 7.16723 15.5192 7.56695L13.0535 11.4852C12.6341 12.1517 11.7336 12.3153 11.1066 11.8389L9.18407 10.3782C9.01123 10.2469 8.77228 10.2461 8.59855 10.3762L5.88843 12.4062C5.50396 12.6942 5.00692 12.2341 5.26433 11.8286Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </symbol>
    <svg
      id="shield-ok"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34875 11.5L11.3488 13.5L15.5 9.34878M11.7253 3.07852L5.72528 4.7928C5.29598 4.91546 5 5.30784 5 5.75432L5 10.8538C5 14.0834 6.55966 17.1141 9.18762 18.9912L11.4188 20.5849C11.7665 20.8332 12.2335 20.8332 12.5812 20.5849L14.8124 18.9912C17.4403 17.1141 19 14.0834 19 10.8538V5.75432C19 5.30784 18.704 4.91546 18.2747 4.7928L12.2747 3.07852C12.0952 3.02721 11.9048 3.02721 11.7253 3.07852Z"
        stroke="#dc7a00"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
    <svg
      id="message-icon"
      fill="none"
      viewBox="0 0 19 19"
    >
      <g clip-path="url(#clip0_1_15849)">
        <path
          d="M9.88357 2.31874C13.9935 2.31874 17.3247 5.62924 17.3189 9.71076C17.3132 13.7253 14.0023 17.0415 9.95871 17.0813C8.44381 17.0961 7.03308 16.66 5.85191 15.9015C5.81636 15.8787 5.77304 15.8717 5.73214 15.8826L4.54976 16.1971L3.07565 16.5892L3.47063 15.1258L3.77504 13.998C3.78639 13.9559 3.77839 13.9113 3.75354 13.8754C2.93108 12.6878 2.44821 11.2504 2.44821 9.70018C2.44821 5.62353 5.77713 2.31874 9.88357 2.31874ZM9.88357 0.773926C4.92567 0.773926 0.892115 4.77822 0.892115 9.70018C0.892115 11.2707 1.30108 12.7889 2.08144 14.1364C2.10114 14.1704 2.10665 14.2106 2.09643 14.2484L1.96755 14.726L1.57256 16.1894L0.875 18.7739L3.4784 18.0814L4.95251 17.6893L5.50378 17.5426C5.54066 17.5328 5.57977 17.5376 5.61335 17.5556C6.92138 18.2582 8.38277 18.6264 9.88357 18.6264C14.8415 18.6264 18.875 14.6221 18.875 9.70018C18.875 4.77823 14.8415 0.773926 9.88357 0.773926Z"
          fill="currentColor"
        />
        <path
          d="M6.45468 5.62632C6.57006 5.57755 6.9216 5.54005 7.28867 5.59802C7.42356 5.61932 7.53769 5.71094 7.59582 5.83364C7.93503 6.54975 8.04748 6.83415 8.2905 7.37995C8.468 7.77861 8.36718 8.24617 8.04587 8.54321C7.89705 8.68079 7.75809 8.80982 7.69645 8.87191C7.5203 9.05801 8.63874 11.2742 10.9524 11.8948C11.0612 11.924 11.1781 11.885 11.2497 11.7986C11.4836 11.5162 11.7085 11.222 11.9407 10.9373C12.0556 10.7965 12.2514 10.7482 12.4214 10.8142C13.1883 11.1116 13.4085 11.2806 14.1772 11.5785C14.3752 11.6552 14.475 11.7692 14.4753 11.9803C14.4763 12.6565 14.2578 13.5537 13.2137 13.8998C13.1045 13.936 12.8278 13.9731 12.7291 13.9792C9.72403 14.1656 5.76849 11.0248 5.36136 8.04759C5.35859 8.0329 5.36414 8.06228 5.36136 8.04759C5.24668 6.45365 6.17049 5.74647 6.45468 5.62632Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_15849">
          <rect
            fill="white"
            height="18"
            transform="translate(0.875 0.773926)"
            width="18"
          />
        </clipPath>
      </defs>
    </svg>
  </svg>
</template>
