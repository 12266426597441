export default defineComponent({
  name: 'SubHeaderWrapper',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <div
        class="
          w-full
          max-w-[338px] md:max-w-[1011px]
          flex flex-column items-center
          gap-[7px]
          mx-auto
        "
      >
        {slots && slots?.default()}
      </div>
    );
  },
});
