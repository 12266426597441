import { cn } from '~/utils';

export default defineComponent({
  name: 'SectionContainer',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <section
        class={[
          cn([
            'w-full', 'selection:bg-green-600 selection:text-paragraph',
            'inline-flex flex-column items-center justify-center flex-wrap',
            'transition duration-[1s] fade-enter',
          ]),
        ]}
      >
        {slots && slots?.default()}
      </section>
    );
  },
});
