export default defineComponent({
  name: 'SectionWrapper',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <div
        class="
          w-full h-full
          max-w-screen-xl min-h-screen
          relative
        "
      >
        {slots && slots?.default()}
      </div>
    );
  },
});
