export default defineComponent({
  name: 'ContentWrapper',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <div
        class="
          text-zinc-100
          w-full
          max-w-container md:max-w-screen-lg lg:max-w-containerLXl
          mx-auto
          relative
        "
      >
        {slots && slots?.default()}
      </div>
    );
  },
});
