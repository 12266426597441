export default defineComponent({
  name: 'ContentContainer',
  setup (props, { slots }): { slots: any } {
    return {
      slots,
    };
  },
  render () {
    const { slots } = this;
    return (
      <div
        class="
          flex justify-center items-center
          w-full
          relative
        "
      >
        {slots && slots?.default()}
      </div>
    );
  },
});
